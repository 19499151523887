import React, { useEffect, useRef, useState } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { EmployeeMasterBranchWiseGetData, getEmployeeListForSeniorityAPIURL, saveEmployeeListForSeniorityAPIURL } from '../../CommonServices/EmployeeMasterAPIURL';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import FastRewindIcon from '@mui/icons-material/FastRewind';

const newData = [
    {
        "empId": 1,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 2,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    },
    {
        "empId": 3,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 63,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    },
    {
        "empId": 4,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 5,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    },
    {
        "empId": 6,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 7,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    },
    {
        "empId": 8,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 9,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    },
    {
        "empId": 10,
        "empNameMr": "खाडिलकर  अश्विनी अभय ",
        "empNameEn": "Khadilkar  Ashwini  Abhay",
        "seniorityNo": 25
    },
    {
        "empId": 11,
        "empNameMr": "शिंदे संजय आनंदराव",
        "empNameEn": "shinde Sanjay Anandrao",
        "seniorityNo": 63
    }
]


const SeniorityEmployeeForm = () => {
    const titleId = "Set Seniority";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const scrollableContainerRef = useRef(null);

    const [loaderOption, setLoaderOption] = useState(false);
    const [data, setData] = useState([]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newOrder = Array.from(data);
        const [movedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, movedItem);

        // Update the seniorityNo based on the new order
        const updatedOrder = newOrder.map((item, index) => ({
            ...item,
            seniorityNo: index + 1
        }));

        setData(updatedOrder);
    };


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        // Uncomment and implement the API call if needed
        setLoaderOption(true);
        if (authUser.branchId != null && authUser.deptId != null) {
            await axios(`${getEmployeeListForSeniorityAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setLoaderOption(false);
                });
        }
    };

    const saveSeniorityData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const saveObj = data.map((item, index) => ({
            ...item,
            sectionId: authUser.deptId,
            branchId: authUser.branchId
        }));

        // console.log(JSON.stringify(saveObj));

        await axios.post(saveEmployeeListForSeniorityAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    navigate('/Home/m009/0');
                    setLoaderOption(false);
                    toast.success("Employee Seniority done successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save Failed.")
                }
            }).catch(err => {
                console.log("Employeee seniority err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

    }

    const onDragUpdate = (update) => {
        const scrollableContainer = scrollableContainerRef.current;
        if (!scrollableContainer) return;

        const { clientY } = update.destination ? update.destination : update.source;
        const { top, bottom } = scrollableContainer.getBoundingClientRect();
        const buffer = 50; // Buffer zone to start scrolling

        if (clientY - top < buffer) {
            scrollableContainer.scrollTop -= 10; // Scroll up
        } else if (bottom - clientY < buffer) {
            scrollableContainer.scrollTop += 10; // Scroll down
        }
    };

    const handelkeyPress = (event) => {
        if (event.key === "Enter") {
            if (data !== null || data != "") {
                event.preventDefault();
                let btn = document.querySelector('.seniorityBtn')
                if (btn !== null) {
                    //console.log('Enter is pressed!');
                    btn.click();
                }
            }
        }
    };

    useEffect(() => {
        //console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);


    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={saveSeniorityData}>
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <button type='button' onClick={() => navigate('/Home/m009/0')} className=' btn btn-sm mb-2' style={{ color: '#fff', backgroundColor: '#CE3B08' }}><FastRewindIcon fontSize="small" />Back</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className='table-responsive' style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                                        <table className="table table-bordered">
                                            <thead className="table-Default">
                                                <tr>
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">Sr. No.</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">Employee Name</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">Birth Date</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">Joining Date</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">Designation</th>
                                                </tr>
                                            </thead>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <tbody
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                        style={{
                                                            borderTop: "2px solid #464de4",
                                                            borderBottom: "2px solid #464de4",
                                                            backgroundColor: snapshot.isDraggingOver ? "#fff" : "inherit" // Change background color while dragging
                                                        }}
                                                    >
                                                        {data.map((item, index) => (
                                                            <Draggable key={item.empId.toString()} draggableId={item.empId.toString()} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style,
                                                                            backgroundColor: snapshot.isDragging ? "#FFD3D3" : "inherit" // Change background color while dragging
                                                                        }}
                                                                    >
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px' }}>{index + 1}</td>
                                                                        <td
                                                                            style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}
                                                                        >
                                                                            {authUser.branchMedium === 1 ? item.empNameMr : item.empNameEn}
                                                                        </td>
                                                                        <td
                                                                            style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}
                                                                        >
                                                                            {item.birthDate}
                                                                        </td>
                                                                        <td
                                                                            style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}
                                                                        >
                                                                            {item.joiningDate}
                                                                        </td>
                                                                        <td
                                                                            style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}
                                                                        >
                                                                            {authUser.branchMedium === 1 ? item.designationMr : item.designationEn}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </table>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <button type='submit' className='seniorityBtn btn btn-primary btn-sm mb-2'><AddIcon fontSize="small" />Save Changes</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
};

export default SeniorityEmployeeForm;
